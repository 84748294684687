import {
  content,
  contentDefault,
  image,
  location,
  locationDefault,
  master_plan,
  master_planDefault,
} from "@/store/model";
import { default_image } from "@/core/config/AppConfig";

export interface Experience {
  _id?: string;
  name: string;
  slug: string;
  content: string;
  contents: content[];
  publish_by: string;
  address: string;
  seo: {
    robot: string;
    title: string;
    keywords: string;
    description: string;
  };
  location: location;
  images: image[];
  published_at: Date;
  thumbnail: image;
  status: string;
  short_content: string;
  video: string;
  type: string;
}
export const ExperienceDefault: Experience = {
  name: "",
  slug: "",
  content: "",
  contents: [contentDefault] as content[],
  publish_by: "",
  address: "",
  seo: {
    robot: "index, follow",
    title: "",
    keywords: "",
    description: "",
  },
  images: [] as image[],
  published_at: {} as Date,
  location: locationDefault,
  thumbnail: default_image,
  status: "active",
  short_content: "",
  video: "",
  type: "du_an",
};
